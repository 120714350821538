@import "bootstrap-custom";
@import "custom";

html {
  font-size: 98%;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Source-Sans-Pro, sans-serif;
  font-weight: 400;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a,
button {
  border: none;
  outline: none;
  cursor: pointer;
  color: $primary-txt-color;
  transition: $time1;

  &:hover,
  &:focus,
  &:active {
    border: none;
    outline: none;
    text-decoration: none;
    color: $secondary-txt-color;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $header-txt-color;
}

p {
  color: $paragraph-txt-color;
  text-align: justify;
}

p:last-child {
  margin: 0;
}
