.img-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
}

.accent-color {
  color: $accent-bg-color;
}

button.block-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.fade {
  animation: fade .7s 1 ease-in-out;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
