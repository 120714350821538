.player-wraper {
    background-color: #fbfbfb;
    margin-top: 25px;
    padding: 10px;
    border-radius: 5px;
    border-left: 5px solid $accent-bg-color;
    // border-bottom: 5px solid $accent-bg-color;
    
    @media ($small) {
        padding: 15px 25px;
    }
}

.player {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: $dark-bg-color;
    color: #ffffff;
    margin: 20px 0 10px 0;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0 0 10px #333333;
}

.player .button {
    margin: 0;
    background-color: #fff;
}

.progress-bar {
    display: block;
    position: relative;
    width: 50%;
    height: 8px;
    cursor: pointer;

    @media ($medium) {
        width: 70%;
    }
}


.progress, .bar, .time-handle, .time-float {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;

}

.progress {
    background-color: $accent-bg-color;
    max-width: 100%;
    z-index: 1;
}

.bar {
    width: 100%;
}

.time-handle, .time-float {
    background-color: $light-bg-color;
    width: 100%;
}

.player-time-info {
    font-size: 12px;

    @media ($small) {
        font-size: 14px;
    }
}

.player-info-title, .player-info-author {
    line-height: 1.2;
}

.player-info-title {
    font-size: 18px;
    color: $paragraph-txt-color;
}

.player-info-author {
    font-size: 16px;
    color: $secondary-txt-color;
}