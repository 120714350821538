.partner-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  height: 150px;
  width: 100%;
}

// .partner-item {
//   width: auto;
//   height: 100%;
//   display: block;
//   max-width: calc(1vw + 70px);
//   max-height: calc(1vw + 70px);
//   padding: 15px 5px;

//   @media ($small) {
//     max-width: calc(1vw + 90px);
//     max-height: calc(1vw + 90px);
//   }

//   @media ($medium) {
//     max-width: calc(2vw + 90px);
//     max-height: calc(2vw + 90px);
//   }

//   @media ($large) {
//     max-width: calc(3vw + 120px);
//     max-height: calc(3vw + 120px);
//     padding: 10px;
//   }

//   @media ($xlarge) {
//     max-width: 180px;
//     max-height: 180px;
//     padding: 10px 20px;
//   }
// }

// .partner-item a {
//   display: block;
// }

.partner-item {
  display: block;
  padding: 10px;
  max-height: 70px;
  height: 100%;
  width: auto;

  @media ($small) {
    padding: 12px;
    max-height: 85px;
  }

  @media ($medium) {
    padding: 15px;
    max-height: 95px;
  }

  @media ($large) {
    padding: 10px;
    max-height: 100px;
  }

  @media ($xlarge) {
    padding: 5px;
    max-height: 90px;
  }
}

.partner-item img {
  height: 100%;
  width: auto;
}
