.program-list {
  @media ($large) {
    padding-right: 30px;
  }

  @media ($xlarge) {
    padding: 0;
  }
}

.reduce-height {
    position: relative;
    overflow: hidden;
    height: 400px;

    @media ($xlarge) {
        height: 500px;
    }
}

.reduce-height::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
    z-index: 1;

    @media ($large) {
        height: 25px;
    }
}

.program .img-wrapper {
  display: none;
  border: 10px solid $primary-border-color;
  border-radius: 0;
  margin: 150px 0 0 auto;

  @media ($large) {
    display: block;
    width: 300px;
    height: 450px;
  }

  @media ($xlarge) {
    width: 400px;
    height: 600px;
  }
}

.program-list .btn-nav-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  @media ($medium) {
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: -8px;
    margin-right: -8px;
  }

  @media ($large) {
    margin-bottom: 25px;
  }
}

.program-list .btn-nav {
  outline: none;
  border: none;
  background: none;
  margin: 0;
  font-weight: 700;
  padding: 3px 8px;
  transition: $time1;

  &:hover {
    color: $secondary-txt-color;
  }

  @media ($small) {
    padding: 3px 6px;
  }

  @media ($medium) {
    padding: 3px 8px;
  }

  @media ($xlarge) {
    font-size: 18px;
  }
}

.program-list td {
  vertical-align: top;
  padding: 2px 5px;

  @media ($medium) {
    padding: 3px 10px;
  }
}

.program-list tr {
  background-color: #fff;
  transition: $time1;
  cursor: default;

  &:hover {
    opacity: 0.7;
  }
}

.program-list tr:nth-child(odd) {
  background-color: $primary-border-color;
}

.program-list .program-time {
  font-weight: 700;
  min-width: 100px;

  @media ($medium) {
    min-width: 110px;
  }
}
